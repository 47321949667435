import { Component, createRef } from "react";
import Head from "next/head";
import Link from "next/link";
import Page from "../layouts/main";
import CTA from "../components/CTA/cta.module";
import Footer from "../components/Footer/footer.module";
import styles from "../styles/modules/404.module.scss";
import settings from "../util/settings";

export default class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      analytics: {},
    };
    this.page = createRef();
  }

  componentDidMount() {
    if (this.page.current) {
      this.setState({ ...this.state, analytics: this.page.current.analytics });
    }
  }

  render() {
    const { analytics } = this.state;
    return (
      <Page id={styles["page-404"]} ref={this.page}>
        <Head>
          <title>Not Found - Kudos Golf</title>
        </Head>

        <section className={`section-content ${styles["section-404"]}`}>
          <h2 className={styles.header}>We're sorry :(</h2>
          <h4 className={styles.subheader}>
            The page you're looking for doesn't exist.
            <br />
            If you have any questions, please contact us{" "}
            <CTA
              href="/contact-us"
              className="text-link"
              text="here"
              analytics={analytics}
              src={settings.isServer() ? "/404" : window.location.pathname}
              dest="/contact-us"
            />
            .
          </h4>
        </section>

        <Footer />
      </Page>
    );
  }
}
